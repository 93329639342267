.brand-gradient {
    background: #283263;
    background: -moz-linear-gradient(70deg, #283263 0%, #4172bc 100%);
    background: -webkit-linear-gradient(70deg, #283263 0%, #4172bc 100%);
    background: linear-gradient(70deg, #283263 0%, #4172bc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#283263', endColorstr='#4172bc', GradientType=1);
}



.d-flex {
    display: flex;
    &.flex-column{
        flex-direction: column;
    }
    &.align-items-center {
        align-items: center;
    }
    &.align-items-end {
        align-items: flex-end;
    }

    &.justify-content-center {
        justify-content: center;
    }
    &.justify-content-between {
        justify-content: space-between;
    }
    &.justify-content-end {
        justify-content: flex-end;
    }
    &.flex-nowrap{
        flex-wrap: nowrap;
    }
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.flex-auto{
    flex: auto;
}

.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}

.lh1{
    line-height: 1.1;
}
.lh2{
    line-height: 1.2;
}
.lh3{
    line-height: 1.3;
}
.lh4{
    line-height: 1.4;
}
.le1{
 letter-spacing: 1px;
}
.le2{
    letter-spacing: 2px;
}

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {

        .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}
