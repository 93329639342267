#landing {
  .first-row {
    margin-top: 100px;
    @media (max-width: 992px) {
      margin-top: 50px;
      .pull-left, .pull-right {
        .align-items-center {
          align-items: flex-start !important;
          margin-bottom: 40px !important;
          > div:first-child {
            min-width: 55px !important;
          }
          h3 {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
  .second-row {
    margin-top: 130px;
    background: url('../images/landing/bg-row.png') center top / cover no-repeat #f5f5f3;
    padding: 80px 0;
    overflow: hidden;

    @media (max-width: 992px) {
      margin-top: 50px;
      background-size: contain !important;
      .row .col-md-6.pr-0 {
        padding-right: 15px !important;
      }
      .row .row {
        //margin: 0 !important;
      }
    }

    li {
      padding: 15px 0;
      @media (min-width: 992px) {
        .dist {
          min-width: 240px;
        }
      }
    }
    .move-img {
      @media (max-width: 1200px) and (min-width: 992px) {

      }
      @media (max-width: 992px) {
        height: auto;
        width: 133%;
        margin-bottom: -20%;
        float: none;
        transform: none;
        /* position: static; */
        left: -50px;
        top: -80px;
      }
      top: -200px;
      left: 40%;
      position: relative;
      margin-bottom: -37%;
      float: right;
      -webkit-font-smoothing: antialiased;
    }
  }
  .third-row {
    img {
      max-height: 90px;
    }
    .row {
      .col-md-4:first-child * {
        max-width: 263px;
      }
      .col-md-4:nth-child(2) *, .col-md-4:nth-child(3) * {
        max-width: 300px;
      }
      @media (max-width: 992px) {
        .col-md-4 {
          margin-bottom: 50px;
        }
        .col-md-4 * {
          max-width: none !important;
        }
      }

    }
  }
  .four-row {
    margin-top: 130px;
    padding: 120px 0;
    @media (max-width: 992px) {
      margin-top: 60px;
      padding: 75px 0;
    }
    h1 {
      max-width: 750px;
    }
  }
  .five-row {
    padding: 120px 0;
    @media (max-width: 992px) {
      padding: 60px 0;
      .row {
        display: block !important;
        a {
          margin-left: 0 !important;
          margin-top: 30px;
          min-width: 270px;
          text-align: center;
        }
      }
    }
  }

  .register {
    margin-top: 65px;
    p {
      color: #fff;
      font-size: 14px;
      margin-left: 25px;
      margin-bottom: 0;
      i {
        margin-right: 5px;
      }
    }

  }

  #hero.landing {
    .home-hero {
      height: 640px !important;
    }
    @media (max-width: 992px) {
      .register {
        display: block !important;
        margin-top: 25px !important;
        p {
          margin-left: 0 !important;
          margin-top: 25px;
          max-width: 80%;
        }
      }
      .home-hero {
        display: block !important;
        height: 470px !important;
        .text-content {
          padding-top: 130px;
        }
      }
    }
    .register {
      @media (max-width: 992px) {
        display: block !important;
        p {
          margin-left: 0 !important;
        }
      }
      margin-top: 65px;
      p {
        color: #fff;
        font-size: 14px;
        margin-left: 25px;
        margin-bottom: 0;
        i {
          margin-right: 5px;
        }
      }
    }
  }

  #registerModal {
    .register-form {
      fieldset:not(:first-child) {
        display: none;
      }
    }
    button[type=submit] {
      border: none !important;
    }

    .step-list {
      @media (max-width: 992px) {
        li:not(.active) {
          display: none;
        }
      }
      li {
        color: #767676;
        font-size: 16px;
        font-weight: 300;
        cursor: pointer;
        &:not(:last-child) {
          padding-bottom: 35px;
        }

        &.active {
          font-weight: 700;
          color: #4172bc;
        }
      }
    }
  }
}

/*
/*
/*
/*
/*
/*
/*
/*
/*
 */

.breadcrumbs {
  margin-top: 35px;
  margin-bottom: 100px;;
  span {
    margin-right: 5px;
  }
  i {
    margin-right: 5px;
  }
}

.banner-page {
  height: 340px;
  padding-top: 80px;
  /*  background-blend-mode: multiply;*/
  width: 100%;
  background-size: cover;
  color: white;
  padding-left: 25px;
  &#properties-hero {
    @include gradient-overlay(rgba(37, 48, 52, 0.8), rgba(60, 72, 76, 0.3), 20deg, '../images/slider-home/2.png');
    background-attachment: fixed;
    background-position: center;
  }
  &#articles-hero {
    @include gradient-overlay(rgba(37, 48, 52, 0.8), rgba(60, 72, 76, 0.3), 20deg, '../images/articles/economia.jpg');
    background-attachment: fixed;
    background-position: center;
  }
  &#about-hero {
    @include gradient-overlay(rgba(37, 48, 52, 0.8), rgba(60, 72, 76, 0.3), 20deg, '../images/local-apl.jpg');
    background-position: top;
    background-attachment: fixed;
    @media screen and (max-width: $screen-sm) {
      background-position: 50% top;
      background-size: 350%;
    }
  }
  &#contact-hero {
    @include gradient-overlay(rgba(37, 48, 52, 0.8), rgba(60, 72, 76, 0.3), 20deg, '../images/contact-hero.png');
    background-attachment: fixed;
    background-position: bottom;
  }
  &#lotting-hero {
    @include gradient-overlay(rgba(37, 48, 52, 0.8), rgba(60, 72, 76, 0.3), 20deg, '../images/loteo-header.png');
    background-position: center bottom;
    background-attachment: inherit;
  }
  &#articles-detail {
    color: $dark-blue;
    padding-left: 0;
    height: auto;
    .breadcrumbs {
      margin-bottom: 0;
      font-size: 12px;
      i {
        margin-right: 5px;
      }
    }
    h1 {
      margin-top: 47px;
    }
    .container {
      padding-left: 15px;
    }
  }
  h1 {
    margin-top: 90px;
  }
  .container {
    padding-left: 40px;
    @media screen and (max-width: $screen-sm) {
      padding-left: 0;
    }
  }
  &.prop-detail {
    height: auto;
    color: $dark-blue;
    padding-left: 0;
    .breadcrumbs {
      margin-bottom: 0;
      i, span {
        margin-right: 3px;
      }
    }
    h1 {
      font-size: 40px;
      margin-top: 40px;
    }
  }
}

.prop-panel {
  background: $dark-blue;
  padding: 80px 25px 20px 25px;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  @include clearfix();
  .dejar-pedido {
    background: $med-blue;
    padding: 30px 14px 20px 14px;
    color: white;
    margin-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
    &:before {
      content: "";
      @include rayas-diagonales($med-blue, $semiblue, 15, 45deg);
      width: 100%;
      position: absolute;
      height: 6px;
      z-index: 99;
      left: 0;
      top: 2px
    }
    &.active {
      transition: all 0.2s linear;
      background: $light-blue;
      cursor: pointer;
      &:before {
        @include rayas-diagonales($light-blue, $med-blue, 15, 45deg);
      }
    }
    @media screen and (max-width: $screen-sm) {
      margin-top: 70px;
    }
    &:hover {
      background: $light-blue;
      cursor: pointer;
      .bell-icon {
        background: $brand-primary;
        i {
          color: white
        }
      }
      &:before {
        @include rayas-diagonales($light-blue, $med-blue, 15, 45deg);
      }
    }
  }
  .first-time {
    background: #4ab084;
    position: absolute;
    color: #fff;
    width: 380px;
    left: 50%;
    bottom: 0;
    padding: 30px 35px 134px;
    transform: translate(-50%, 0%);
    opacity: 0;
    transition: all 0.4s ease-in-out;
    visibility: hidden;
    h5 {
      text-transform: none !important;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    @media screen and (max-width: $screen-sm) {
      width: 100%;
      padding: 14px 35px 170px;
    }
  }
  p {
    margin: 0
  }
  .btn-primary:not(.green) {
    margin-right: 10px;
    width: 100%;
  }
  .btn-secondary {
    width: 100%;
    margin-right: 0;
    display: inline-block;
    border-radius: 0;
  }
  .select2-container {
    min-width: 45px !important;
  }
}

.bell-icon {
  border: 2px solid $brand-primary;
  height: 42px;
  width: 42px;
  background: transparent;
  border-radius: 100%;
  text-align: center;
  line-height: 42px;
  margin-right: 15px;
  i {
    margin-right: 0;
  }
  i.zmdi-close {
    line-height: 39px;
    display: block;
  }
}

.pedido-panel {
  background: $med-blue;
  padding: 80px 25px 20px 25px;
  display: none;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  z-index: 222;
  @include clearfix();
  &.active {
    display: block;
  }
  .prop-values {
    margin: 0 -15px;
    p {
      color: white;
      margin: 3px 0;
      font-weight: $light;
    }
  }
  p#precio-min, p#precio-max {
    display: inline-block;
    width: 50%;
  }
  .seguir-buscando {
    background: $dark-blue;
    padding: 30px 14px 20px 14px;
    color: white;
    margin-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:before {
      content: "";
      @include rayas-diagonales($med-blue, $dark-blue, 15, 45deg);
      width: 100%;
      position: absolute;
      height: 6px;
      z-index: 99;
      left: 0;
      top: 2px
    }
    &:hover {
      background: $light-blue;
      cursor: pointer;
      .bell-icon {
        background: $brand-primary;
        i {
          animation: none
        }
      }
      &:before {
        @include rayas-diagonales($med-blue, $light-blue, 15, 45deg);
      }
    }
  }
}

.highlight-article {
  display: flex;
  @media screen and (max-width: $screen-md) {
    display: block;
    padding: 15px;
    margin-bottom: 30px;
  }
}

.article-image, .artice-desc-high {
  width: 50%;
  flex: 1;
  min-height: 430px;
  padding: 40px;
  background-position: center;
  @media screen and (max-width: $screen-md) {
    background-size: cover;
    width: 100%;
  }
}

.artice-desc-high {
  background: $gray-lighter;
  p.text {
    max-height: 100px;
    margin: 50px 0 90px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.article-search {
  position: relative;
  margin-bottom: 60px;
  float: right;
  width: 300px;
  input {
    background: white;
    border: 2px solid $beige;
    text-transform: none;
    color: $dark-blue;
    padding-left: 25px;
    text-indent: 30px;
    &::-webkit-input-placeholder {
      color: $dark-blue;
      text-transform: none;
      font-weight: $semibold;
    }
    &:focus {
      border: 2px solid $cream;
    }
  }
  .fa-search {
    position: absolute;
    top: 13px;
    left: 17px;
    z-index: 1;
    color: $dark-blue;
  }
  @media screen and (max-width: $screen-md) {
    float: left;
    width: 100%;
  }
}

#suscribite {
  margin-top: 100px;
  background: url('../images/articles/suscripcion-bg.png'), linear-gradient($brand-primary 0%, $brand-primary 100%);
  background-size: contain;
  min-height: 470px;
  color: white;
  text-align: center;
  padding: 40px 0;
  background-repeat: no-repeat;
  background-position: center;
  form {
    margin-top: 50px;
    input[type="text"], textarea {
      border: 2px solid $light-green
    }
  }
}

#contacto-prop-detail {
  margin-top: 100px;
  background: linear-gradient($brand-primary 0%, $brand-primary 100%);
  background-size: contain;
  min-height: 470px;
  color: white;
  text-align: center;
  padding: 40px 0;
  form {
    margin-top: 50px;
    input[type="text"], textarea {
      border: 2px solid $light-green;
      color: white;
      &::-webkit-input-placeholder {
        color: white;
      }
    }
  }
}

#contacto-about-us {
  margin-top: 100px;
  background: #4ab084;
  min-height: 635px;
  color: white;
  padding: 50px 0;
}

.social-container {
  overflow: hidden;
  position: relative;
  float: right;
  width: 42px;
  &.active {
    width: 220px;
    transition: 1.2s all linear;
  }
  .share-icon {
    &#main-share {
      background: $gray-lighter;
      position: relative;
      z-index: 9999;
      margin-right: 0;
    }
    &#facebook, &#linkedin, &#twitter {
      position: absolute;
      background: $brand-primary;
      top: auto;
      i {
        color: $gray-lighter
      }
      &.active {
        animation-name: slideCustom;
        animation-duration: 0.4s;
      }
    }
    &#facebook.active {
      animation-delay: 0s;
      right: 40px
    }
    &#twitter.active {
      animation-delay: 0.4s;
      right: 90px
    }
    &#linkedin.active {
      animation-delay: 0.8s;
      right: 140px
    }
  }
}

//.social-container {
//  width: 42px;
//  display: flex;
//  flex-direction: column;
//  &.active {
//    width: 200px;
//    &#facebook, &#linkedin, &#twitter {
//      opacity: 1;
//      position: relative;
//      animation-name: slideShare;
//      animation-duration: 0.1s;
//      i {
//        color: $gray-lighter
//      }
//    }
//  }
//}

@keyframes slideCustom {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slider-nav-thumbnails {

  .slick-slide {
    padding: 0 7.5px;
    overflow: hidden;
    //height: 144px;
  }

  img {
    max-width: 100%;
    max-height: 144px;
    height: auto;
    display: block;
    cursor: pointer;
    border-width: 4px;
    border-color: transparent;
    border-style: solid;
  }
  .slick-current img {
    border-color: $brand-primary;
  }
  .slick-prev, .slick-next {
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 100%;
    &:hover {
      background: white;
      z-index: 999;
      opacity: 1;
    }
    &:before {
      font-family: $material;
      font-size: 45px;
      color: $dark-blue;
      opacity: 1;
      @media screen and (max-width: $screen-sm) {
        font-size: 45px;
      }
    }
    @media screen and (max-width: $screen-sm) {
      width: 40px;
      height: 40px;
    }
  }
  .slick-prev {
    left: 10px;
    &:before {
      content: '\f2fa';
    }
    &:focus {
      background: white;
    }
  }
  .slick-next {
    right: 10px;
    &:before {
      content: '\f2fb';
    }
    &:focus {
      background: white;
    }
  }
}

.slider-detail {
  margin-top: 60px;
  margin-bottom: 20px;
  figure {
    margin: 0 !important;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }

}

#property-info {
  margin-top: 50px;
  @include clearfix();
  @media screen and (max-width: $screen-sm) {
    margin: 0;
  }
}

.seller-profile {
  min-height: 220px;
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;
  padding: 50px 35px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  img {
    margin-right: 20px;
  }
  i {
    margin-right: 0;
    width: 20px;
  }
  span.contact {
    margin-left: 0;
  }
  &#author {
    align-items: flex-start;
    min-height: initial;
    padding: 30px 0;
    margin-bottom: 60px;
    border-top: 2px solid #deded6;
    border-bottom: 2px solid #deded6;
    @media screen and (max-width: $screen-sm) {
      margin: 0;
      margin-bottom: 60px;
      min-height: inherit;
    }
  }

  @media screen and (max-width: $screen-sm) {
    margin: 60px 0;
    padding: 28px 0;
    min-height: inherit;
  }

}

.map-container {
  position: relative;
}

.prop-window {
  padding: 30px;
  position: absolute;
  max-width: 330px;
  min-height: 130px;
  top: 30px;
  left: 30px;
  z-index: 99999;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5)
}

#about-us {
  padding: 100px 0;
  h3 {
    max-width: 650px;
    margin: 0 auto;
    line-height: 30px;
    @media screen and (max-width: $screen-sm) {
      max-width: 100%;
      font-size: 18px;
      line-height: 1.67;
    }
  }
  @media screen and (max-width: $screen-sm) {
    padding: 80px 0;
    margin-bottom: 0;
  }
}

#our-services, #our-mision, #team {
  margin-bottom: 70px;
  h2 {
    text-align: center;
    @media screen and (max-width: $screen-sm) {
      margin-top: 0;
      margin-bottom: 35px;
    }
  }
  @media screen and (max-width: $screen-sm) {
    padding: 80px 0;
  }
}

.service-block {
  @media screen and (max-width: $screen-sm) {
    padding: 30px;
  }
}

#our-mision {
  background: $dark-blue;
  color: white;
  margin-bottom: 0;
  h2 {
    color: $brand-primary
  }
  hr {
    border-color: $brand-primary;
    border-width: 4px;
    text-align: center;
    margin: 50px auto 40px auto;
    padding: 0 95px;
    width: 31%;
  }
  p.text {
    color: white
  }
}

#team {
  .team-div {
    margin: 70px 0 50px;
    @media screen and (max-width: $screen-sm) {
      margin-bottom: 50px;
      margin-top: 0;
    }
  }
  .prof-card {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 18px;
    }
    h5 {
      margin-bottom: 18px;
    }
    i {
      margin-right: 6px;
    }
    span {
      font-size: 12px;
      @media screen and (max-width: $screen-sm) {
        word-break: break-all;
      }
    }
    @media screen and (max-width: $screen-md) {
      margin-bottom: 60px
    }
  }
}

#contacto-page {
  padding: 50px 0 70px 0;
  h5 {
    margin-bottom: 60px;
  }
  input, textarea {
    border-color: $gray-default;
    color: $med-blue;
    &::-webkit-input-placeholder {
      color: $med-blue;
      font-weight: 600
    }
    &:focus {
      border-color: $med-blue
    }
  }
}

.static-map {
  position: relative;
  .apl-window {
    position: absolute;
    min-width: 300px;
    margin-left: 20%;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    padding: 50px 33px;
    font-size: 18px;
    z-index: 999;
    @media screen and (max-width: $screen-sm) {
      margin: 0 10px;
      top: 50px;
      min-width: calc(100% - 20px);
      transform: none;
    }
  }

  .static-map-img {
    height: 462px;
    position: relative;
    background: url('../images/staticmap.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: $screen-sm) {
      height: 540px;
      background: url('../images/mapa-apl.png');

    }
  }

}

.round-bg {
  width: 78px;
  height: 78px;
  border-radius: 100%;
  border: 2px solid #c5c5c5;
  line-height: 90px;
  text-align: center;
  margin: 20px auto;
  i {
    margin-right: 0;
  }
}

#follow-us {
  padding: 50px 0 70px 0;
  h2 {
    margin-bottom: 45px;
  }
}

#about-lotting {
  padding: 50px 0 70px 0;
  h6 {
    text-transform: none;
    margin: 48px auto 37px auto;
    font-weight: 300;
    max-width: 744px;
    line-height: 1.25;
    letter-spacing: 0.6px;
  }
}

.lotting-services {
  margin-bottom: 50px;
  img {
    max-width: 43px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    float: left;
  }
  h5 {
    text-transform: none;
    display: inline-block;
    margin-left: 15px;
    float: left;
    margin-top: 10px;
  }
  @media screen and (max-width: $screen-sm) {
    margin-bottom: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 0;
    width: 100%;
  }
}

#lotting-map {
  padding: 50px 0 70px 0;
  color: white;
  @include gradient-overlay(rgba(36, 141, 110, 1), rgba(81, 193, 145, 0.6), 90deg, '../images/loteo-header.png');
  height: 321px;
  background-position: 30% 30%;
  background-size: 180%;
  text-align: center;
  h5 {
    text-transform: none;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 0.6px;
    margin: 47px 0 57px 0;
    font-size: 16px;
  }
  button {
    border-color: white;
    &:hover {
      color: white;
      background: lighten($brand-primary, 10%);
      border-color: white
    }
  }
  @media screen and (max-width: $screen-sm) {
    background-size: cover !important;
  }
}

#lotting-payment {
  padding: 50px 0 70px 0;
}

.video-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-align: left;
  color: $dark-blue;
  text-transform: none;
  margin-bottom: 30px;
}

.more-articles {

  a {
    display: block;
    font-weight: normal;
    text-transform: none;
    font-size: 18px;
    padding: 31px 45px 31px 20px;
    letter-spacing: 0.7px;
    text-align: left;
    margin: 0;
    @media screen and (max-width: $screen-sm) {
      padding: 30px 20px;
    }
  }

  hr {
    margin: 0
  }
}

.mapMobileContent {
  position: fixed;
  top: 0px;
  left: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100vh;
  z-index: 150;
  transition: all .3s;
  #map-off {
    width: 100% !important;
    height: 100% !important;
    padding-top: 0 !important;
    #map {
      height: 100% !important;
    }
  }
  &.opened {
    transform: translateX(0);
  }
}

.mobileMapBtn {
  @media (max-width: 992px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  display: none;
  z-index: 155;
  position: fixed;
  bottom: 20px;
  width: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  &.opened {
    i.fa-globe {
      display: none;
    }
    i.fa-times {
      display: block !important;
    }
  }
  a.btn-blue.border {
    background-color: #FFFFFF;
    font-size: 12px;
    padding: 5px !important;
    i {
      font-size: 18px;
    }
  }
}

.currencySymbol {
  max-width: 116px;
  @media (max-width: 400px) {
    max-width: 100px;
  }
  background-color: #fff;
  color: #484848;
  border-radius: 3px;
  height: 21px;
  display: flex;
  align-items: center;
  padding-left: 7px;
  font-weight: 500;
  font-size: 13px;
  input, input:focus {
    background-color: transparent;
    margin: 0 !important;
    outline: none !important;
    font-size: 13px;
    color: #484848;
    height: 100%;
    padding: 0 7px 0 5px;
    border: none;
    line-height: 1;
    font-weight: 500;
  }
}

.irs-to, .irs-from, .irs-single {
  display: none;
}

.single-prop-hero {
  width: 100%;
  position: relative;
  padding-bottom: 28%;
  margin-top: 90px;
  min-height: 230px;
  .img {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.inner-page.single-prop {
  .slider-content {
    position: relative;
    .pagingInfo {
      position: absolute;
      right: 20px;
      bottom: 10px;
      z-index: 30;
    }
    @media (max-width: 992px) {
      .slick-next, .slick-prev {
        top: 61%;
      }
    }
    .slick-next {
      right: 20px;
      width: 30px;
      height: 30px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
      cursor: pointer;
      z-index: 50;
      &:before, &:after {
        content: none;
      }
    }
    .slick-prev {
      left: 20px;
      width: 30px;
      height: 30px;
      border-top: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(-45deg);
      cursor: pointer;
      z-index: 50;
      &:before, &:after {
        content: none;
      }
    }
  }
  .text-content {
    .more-link, .less-link {
      margin-top: 15px;
      font-size: 13px;
      color: #4172bc;
      font-weight: 700;
      display: block;
      &:after {
        content: '\f107';
        font-family: FontAwesome;
        padding-left: 5px;
        padding-top: 2px;
      }
      &.less-link:after {
        content: '\f106';
      }
    }
  }
  .char-block {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #c5c5c5;
    @media (max-width: 992px){
      &:last-child{
        margin-bottom: 30px;
      }
    }
    h5 {
      color: #484848;
    }
    .items {
      display: flex;
      > * {
        flex: auto;
        p {
          font-size: 16.55px;
          color: #767676;
          &:not(.not):before {
            font-family: FontAwesome;
            content: '\f00c';
            color: #767676;
            padding-right: 5px;
          }
        }
      }
    }
  }
  .map-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    > div {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  .real-estate-block {
    .first {
      padding: 30px;
      background-color: #f5f5f3;
      .rating {
        i {
          font-size: 16.56px;
          color: #4172bc;
        }
      }
    }
    .last {
      background-color: #d5e2f6;
      padding: 30px;
    }
    @media (max-width: 992px) {

      .first, .last {
        padding: 30px 15px;
        span.fs12{
          display: block;
        }
      }

    }
  }
  .fix-mobile {
    width: 100%;
    background-color: #FFFFFF;
    opacity: 0;
    transition: all .3s;
    transform: translateY(-100%);
    position: absolute;
    top: 0;
    left: 0;
    .is-sticky & {
      box-shadow: 0 0 3px 1px
    }
  }
  #sticky-wrapper:not(.is-sticky) {
    height: 0 !important;
    overflow: hidden;
  }
  .is-sticky {
    .fix-mobile {
      transform: translateY(0);
      box-shadow: 0 0 3px 1px;
      position: relative;
      opacity: 1;
    }
  }
  @media (max-width: 992px) {
    #prop-location {
      order: 3;
    }
    .prop-char {
      order: 4;
    }
    .prop-block {
      .btn-blue.border.sm {
        display: none;
      }
    }
  }
  margin-bottom: 0 !important;
}

.select2-selection.select2-selection--single.select-order {
  max-height: 28px;
  border-color: #c5c5c5 !important;
  #select2-order-container {
    line-height: 28px !important;
  }
  * {
    max-height: 28px;
    line-height: 28px !important;
  }
}

.inner-page.single-prop.inmo {

  .logo-inmo {
    background-size: cover;
    background-position: center;
    padding-bottom: 70%;
    width: 100%;
  }
  .block-corredor {
    background-color: #f3f3f5;
    padding: 15px;
  }
  .block-evaluation {
    margin-bottom: 30px;
    padding-bottom: 23px;
    border-bottom: 1px solid #c5c5c5;
  }

  @media (max-width: 992px) {
    .container > .row {
      display: flex;
      flex-direction: column;
      > :first-child {
        order: 2;
      }
      > :last-child {
        order: 1;
      }
    }
    div.col-md-8.inmoList div.corredores-block .block-corredor{
      position: relative;

    }
    div.col-md-8.inmoList div.corredores-block div.rating{
      position: absolute !important;
      right: 10px;
      bottom: 15px;
    }
  }
}