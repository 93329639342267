.select2-container {
  margin-bottom: 20px;
  text-align: left;
  min-width: 70px !important;
}

.select2-container--default.select2-container--focus {
  .select2-selection__rendered {
    border-color: #8baddf !important;
  }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #8baddf !important;
}

//single selection
.select2-container--default .select2-selection--single {
  height: 42px !important;
  .select2-selection__placeholder {
    color: #484848 !important;
    font-weight: 700;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 14px;
}

//multiple selection
.select2-container--default .select2-search--inline .select2-search__field {
  color: #484848 !important;
  text-transform: none;
  font-family: $font;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px !important;
  margin-top: 0;
  letter-spacing: normal;
  padding-left: 3px;
  &::-webkit-input-placeholder {
    color: #484848 !important;
    text-transform: none;
  }
}

.select2-container--default .select2-selection--multiple {

  height: 42px !important;

  &[aria-expanded="true"] {
    height: inherit
  }

  .select2-selection__choice {
    background: #fff !important;
    border-radius: 0 !important;
    border: 0 !important;
    font-size: 12px !important;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .select2-selection__rendered {
    display: block;
    .select2-search--inline {
      padding-left: 8px;
    }
  }
  .select2-selection__choice__remove {
    float: right;
    color: $dark-blue;
    font-weight: bolder;
    margin-left: 5px;
  }

}

//ambos
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
  background: #FFFFFF;
  border-radius: $radius-primary !important;
  border-color: transparent;
  border-width: 2px !important;
  line-height: 36px !important;

  .select2-selection__rendered {
    padding-left: 15px !important;
    color: $light-beige !important;
    font-family: $font;
    font-weight: $semibold;
    font-size: 14px;
    line-height: 36px !important;
    position: relative;
    .select2-selection__placeholder {
      text-transform: none;
    }
  }
  &.active {
    border-color: $brand-primary !important;
  }
  &.active {
    background: #d5e2f6;
    .select2-selection__rendered {
      color: #484848 !important;
      font-weight: 700;
    }
  }
}

.select2-selection__arrow i {
  color: #41599b !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: $light-beige !important;
  font-family: $font;
  font-weight: $semibold;
  font-size: 14px;
  line-height: 36px !important;
  background: $dark-blue !important;
}

.select2-dropdown {
  border: none !important;
}

.select2-container--default .select2-results {

  .select2-results__options {
    border: 2px solid #d8d8d8 !important;
    color: $light-beige !important;
    font-family: $font;
    font-weight: $semibold;
    font-size: 14px;
    line-height: 36px !important;
    background: $med-blue;
  }
}

.select2-container--open .select2-dropdown--below {
  border-top: none !important;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none !important;
}

.select2-container--open {
  background: transparent !important;
}

.select2-results__option[aria-selected=true] {
  position: relative;
  &:after {
    content: "\f14a";
    width: 20px;
    height: 20px;
    font-family: FontAwesome, serif;
    position: absolute;
    font-size: 16px;
    border: none !important;
    background: none !important;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1.3;
  }
}

.select2-results__option {
  position: relative;
  &:after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    border: 1px solid #c5c5c5;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.select2-selection--single[aria-labelledby="select2-preciomin-container"], .select2-selection--single[aria-labelledby="select2-preciomax-container"] {
  border-right: none !important;
}

[aria-multiselectable="true"] .select2-results__option, [aria-expanded="true"] .select2-results__option {
  background-color: #fffdfd;
  color: #797979;
  font-weight: 300;
  line-height: 1 !important;
  padding: 10px 10px 10px 30px !important;

  &:after {
    border-radius: 3px !important;
  }
  &.select2-results__option--highlighted {
    background-color: #fffdfd !important;
    color: #4172bc !important;
    font-weight: 700;
    line-height: 1 !important;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #d8d8d8 !important;
  }
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #fffdfd;
  color: #4172bc !important;
  font-weight: 700 !important;
}

.select2-selection--multiple {
  .select2-selection__rendered {
    height: 100%;
    display: flex !important;
    flex-wrap: nowrap !important;
    &, * {
      white-space: nowrap !important;
    }
  }
  &.active {
    background-color: #d5e2f6 !important;
    li.select2-selection__choice {
      font-size: 14px !important;
      color: #484848 !important;
      font-weight: 700 !important;
      background-color: transparent !important;
    }
    .select2-selection__choice__remove, .select2-search.select2-search--inline {
      display: none !important;
    }
  }

  .select2-selection__clear {
    display: none !important;
  }
  .select2-selection__choice {
    padding: 0 !important;
    display: inline-block !important;
    margin: 0 !important;

    &:not(:nth-last-child(2)) {
      &:after {
        content: '+';
        display: inline-block !important;
        padding: 0 3px;
      }
    }
  }
}

.select2-selection--single.input-dropdown {
  &[aria-expanded="true"] {
    border-color: #8baddf;
  }
  &.active {
    border-color: #4172bc !important;
    background-color: #d5e2f6 !important;
    .select2-selection__rendered {
      font-size: 14px !important;
      color: #484848 !important;
      font-weight: 700 !important;
      background-color: transparent !important;
    }
  }
}

.select2-selection:not(.active) {
  .badge {
    display: none;
  }
}

.badge {
  position: absolute;
  top: 50%;
  right: 15px;
  background-color: #fa5949;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  &:not(.dif):after {
    content: '';
    position: absolute;
    right: 0;
    transform: translateX(100%);
    height: 100%;
    width: 13px;
    top: 0;
    background-color: #d5e2f6;
  }
  &.dif{
    right: 30px;
  }
}

.select2-dropdown.input-dropdown {
  &.select2-dropdown--below {
    transform: translateY(-40px);
  }
  &.select2-dropdown--above {
    transform: translateY(40px);
    position: relative;
    padding-bottom: 40px;
    .select2-search.select2-search--dropdown {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  background-color: transparent;
  margin-bottom: 4px;
  .select2-search__field {
    border: none;
    padding-left: 14px;
    font-size: 14px;
    color: #484848;
    height: 32px;
    line-height: 32px;
  }
  .select2-search.select2-search--dropdown {
    background-color: transparent;
  }
  .select2-results__options {
    background-color: #fffdfd !important;
    li {
      line-height: 1 !important;
      padding: 10px;
      color: #767676;
      &.select2-results__option--highlighted {
        background-color: #4172bc !important;
        color: #FFFFFF !important;
      }
      &:after {
        content: none;
      }
    }
  }
}

.select2-search.select2-search--inline {
  max-width: 100%;
}