#main-header {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 250;
    padding-top: 25px;
    max-height: 98px;
    min-height: 1vh;
    transition: all .3s;
    &.white{
        background-color: #FFFFFF !important;
        background-image: none !important;
        padding-bottom: 15px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.4);
        @extend .slideInDown;
        .hamburger span{
            background-color: #283162 !important;
        }
        .the-nav{
            li{
                a:not(.public,.btn-login) {
                    color: #484848;
                    &:after{
                        background-color: #484848;
                    }
                }
                a.public{
                    border-color: #4172bc;
                    color: #484848;
                }
            }
        }
    }
    .the-nav {
        li {
            a.public {
                border: 1px solid #fff;
                color: #fff;
                padding: 10px 20px;
                transition: all .3s;
                background-color: transparent;
                font-weight: 300;
                font-size: 14px;
                border-radius: $radius-primary;
                &:hover {
                    background-color: #fff;
                    color: #4172bc;
                }
                &:active {
                    border-color: #d5e2f6;
                    color: #4172bc;
                    background-color: #d5e2f6;
                }
                @media (max-width: 992px){
                    padding: 10px 15px;
                    font-size: 13px;
                }
            }
            a:not(.public,.btn-login) {
                font-weight: 300;
                font-size: 14px;
                color: #fff;
                position: relative;
                padding: 0 10px;
                transition: all .3s;
                &:active {
                    color: #a8c8f9;
                    &:after {
                        background-color: #a8c8f9;
                    }
                }
                &:hover:after {
                    max-width: 300px;
                    opacity: 1;
                    transition: all .3s;
                }
                &:after {
                    content: '';
                    transition: all .3s;
                    position: absolute;
                    bottom: -6px;
                    max-width: 1px;
                    opacity: 0;
                    width: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 3px;
                    background-color: #fff;
                }
            }

        }
    }

    @media (max-width: 992px) {
        &.menu-open {
            //max-height: 1024px;
            min-height: 100vh;
            background: url(../images/bg-mobile.png) center -50px $brand-secondary no-repeat;
            background-size: 100% auto;
            ul{
                opacity: 1;
                max-height: 1024px;
            }
        }
        .hamburger {
            position: absolute;
            right: 30px;
            top: 30px;
            width: 30px;
            height: 22px;
            &.open {
                span {
                    &:first-child {
                        transform: translateY(-50%) rotate(45deg);
                    }
                    &:last-child {
                        transform: translateY(-50%) rotate(-45deg);
                    }

                    &:first-child, &:last-child {
                        top: 50%;
                    }
                    &:nth-child(2) {
                        transform: translateX(-15px);
                        opacity: 0;
                    }
                }
            }
            span {
                transition: all .3s;
                height: 4px;
                width: 30px;
                display: block;
                position: absolute;
                background-color: #fff;
                &:first-child {
                    top: 0;
                    left: 0;
                }
                &:nth-child(2) {
                    top: 9px;
                    left: 0;
                }
                &:last-child {
                    top: 18px;
                    left: 0;
                }
            }
        }
        .container > div {
            display: block;
        }

        ul {
            opacity: 0;
            max-height: 0;
            transition: opacity .4s;
            overflow: hidden;
            display: block !important;
            li {
                display: block;
                padding-top: 40px;
                &.first{
                    display: flex !important;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #8baddf;
                    padding-bottom: 30px;
                    a{
                        white-space: nowrap;
                    }
                }
                a{
                    text-align: center;
                    display: block;
                }

            }
        }
    }

}


.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}