.home-hero, .int-hero {
  height: 785px;
  width: 100%;
  background: url(../images/bg-primary.jpg) center -50px $brand-secondary no-repeat;
  @media (max-width: 992px) {
    background: url(../images/bg-mobile.png) center -50px $brand-secondary no-repeat;
    background-size: 100% auto;
    height: 470px;
    .text-content {
      margin-top: auto;
      .row {
        padding-top: 0 !important;
      }
    }
    &.home-hero{
      display: block !important;
      height: auto !important;
      .text-content{
        padding-top: 100px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          h2{
            font-size: 20px;
            &:nth-last-child(2){
              display: inline;
            }
            &:last-child{
             display: inline;
            }
          }

        }
      }
    }
  }
  background-size: 100% auto;
  .text-content {
    margin-top: auto;
    .row {
      padding-top: 50px;
    }
    h1, h2 {
      margin: 0;
      line-height: 1.4;
    }
  }

  &.int-hero {
    height: 340px;
    background-position-y: -150px;
    @media (max-width: 992px){
      height: 200px;
      .text-content {
        margin-bottom: 30px !important;
      }
    }
    .text-content {
      margin-bottom: 65px;
    }
  }
}

.slick-slide {
  transition: all 0.2s ease-in-out
}

.slick-list {
  width: 100% !important;
}
