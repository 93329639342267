.required {
    color:#e9266d
}
#success, #error {
    display:none
}
#success span, #erro span {
    display:block;
    position:absolute;
    top:0;
    width:100%
}
#success span p, #error span p {
    margin-top:6em
}
#success span p {
    color:#9bd32d;
}
#error span p {
    color:#c0392b;
}
