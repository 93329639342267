body, html {
  min-height: 100%;
  margin: 0;
  font-family: $font;
  font-size: 16px;
  line-height: 1.25;
  color: #484848;
  @extend .fw100;
}

*:focus, *:active {
  outline: none;
}

a, a:hover, a:visited, a:focus {
  text-decoration: none;
  outline: none !important;
}

::selection {
  color: white;
  background: $brand-primary
}

.modal-content {
  border-radius: 0;
  padding: 50px;
  @media (max-width: 992px) {
    padding: 40px 20px;
  }
}

.fs14 {
  font-size: 14px;

}

.fs12 {
  font-size: 12px;

}

.fs16 {
  font-size: 16px;

}

.fs18 {
  font-size: 18px;

}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.modal-dialog {
  width: 940px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  .modal-header {
    border: none;
    h1, h2, h3, h4, h5 {
      margin-top: 0;
    }
  }
  .modal-footer {
    border: none;
  }
}

/* Párrafos */
p.important, span.important {
  font-size: 18px;
  @extend .fw300
}

p.filter, span.filter {
  font-size: 16px;
  @extend .fw500;
}

p.sm {
  font-size: 14px;
}

.link-default {
  color: #4172bc;
  @extend .fw500;
  font-size: 13px;
  transition: all .3s;
  &:hover {
    color: #8baddf;
  }
  &:active {
    color: #283263;
  }
}

.link-more {
  @extend .fw500;
  font-size: 16px;
  color: #767676;
  transition: all .3s;
  &:hover {
    color: #4172bc;
  }
  &:active {
    color: #283263;
  }
}

@mixin link-icon($unicode) {
  color: #484848;
  font-size: 14px;
  @extend .fw500;
  transition: all .4s;
  &:hover {
    color: #4172bc;
  }

  @if $unicode {
    &:before {
      content: '\#{$unicode}';
      font-family: FontAwesome;
      color: #4172bc;
      font-size: 14px;
    }
  } @else {
    &:before {
      content: 'Falta ícono!';
      font-family: FontAwesome;
      color: #4172bc;
      font-size: 14px;
    }
  }
}

.link-icon {
  @extend .fw500;
  font-size: 16px;
  color: #767676;
  transition: all .3s;
  &:hover {
    color: #4172bc;
  }
  &:active {
    color: #283263;
  }
}

/* fonts Weights */
.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}

.fw500 {
  font-weight: 500;
}

.fw700 {
  font-weight: 700;
}

.fw900 {
  font-weight: 900;
}

/* HEADINGS */

h1 {
  font-size: 40px;
  &.white {
    color: #FFFFFF;
  }
  @media screen and (max-width: $screen-sm) {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
  &.white {
    color: #FFFFFF;
  }
  @media screen and (max-width: $screen-sm) {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
  &.prop {
    font-weight: $light !important;
  }
  @media screen and (max-width: $screen-sm) {
    font-size: 20px;
  }
  &.white {
    color: #FFFFFF;
  }
}

h4 {
  font-size: 20px;
  @media screen and (max-width: $screen-sm) {
    font-size: 16px;
  }
}

h5 {
  font-size: 16px;
  color: #767676;
}

h6 {
  font-size: 14px;
}

.color2 {
  color: #767676;
}

.select-no-m .select2 {
  margin-bottom: 0;
}

.text-container {
  background: $gray-lighter;
  padding: 15px
}

.inner-page {
  margin-top: 100px;
  @media (max-width: 992px) {
    margin-top: 50px;
    overflow: hidden;
    max-width: 100%;
  }
  margin-bottom: 100px;
  @media (max-width: 992px) {
    margin-bottom: 50px;
  }
}

form#contactForm {
  @media (max-width: 992px) {
    .btn-blue {
      width: 100%;
      text-align: center;
    }
  }
}

/* OLDDDDDDD */
/* OLDDDDDDD */
/* OLDDDDDDD */
/* OLDDDDDDD */

p.text {
  font-size: 17px;
  letter-spacing: 0.5px;
  hyphens: auto;
  color: $dark-blue;
  text-align: justify;
  font-weight: normal;
}

span.contact {
  font-weight: $semibold;
  letter-spacing: 0.3px;
  font-size: 14px;
  margin-left: 10px;
  @media screen and (max-width: $screen-sm) {
    word-break: break-all;
    font-size: 12px;
    margin-left: 5px;
  }
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 18px;
  float: left;
  display: flex;
  width: 100%;
  li {
    width: 50%;
  }
}

.semibold {
  font-weight: $semibold;
}

.bold {
  font-weight: $bold;
}

input[type="text"] {
  margin-bottom: 20px !important;
}

i {
  margin-right: 10px;
  & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
    display: inline-block;
  }
}

.icon-box {
  //width:20%;
  float: left;
  i {
    margin-right: 5px !important;
  }
  h5 {
    margin-right: 30px;
  }
}

ul {
  padding-left: 0;
  li {
    list-style: none;
  }
}

.inline-block {
  display: inline-block;
  margin-right: 10px;
}

.fa-2x {
  margin-right: 0;
}

.no-br {
  & > .select2-container .select2-container--default .select2-selection--single {
    border-right: 0 !important;
  }
}

.prop-block {
  margin-bottom: 50px;
  @media (max-width: 992px) {
    margin-bottom: 0;
  }
  transition: all .5s;
  &:hover {
    //box-shadow: 0 3px 0 0 rgba(#4172bc, 1);
  }

  &.dif {
    .prop-image-container {
      position: relative;
    }
    .heart {
      position: absolute;
      cursor: pointer;
      top: 10px;
      left: 10px;
      z-index: 15;
      svg {
        transition: all .4s;
      }
      &:hover, &.active {
        svg {
          fill: #4172bc !important;
          fill-opacity: 1 !important;
          transform: scale(1.1);
          transform-origin: center;
        }
      }
    }
  }
}

.prop-tab {
  background: $dark-blue;
  padding: 15px 20px;
  text-transform: uppercase;
  position: absolute;
  top: -40px;
  margin-left: 30px;
  &:after {
    background: $brand-primary;
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    content: "\f002";
    color: #253034;
    font-family: FontAwesome;
    padding: 12px;
    top: 0;
  }
  span {
    margin-left: 37px;
    color: white;
    font-size: 16px;
  }
  @media screen and (max-width: $screen-sm) {
    margin-left: 0;
    padding: 9px 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    z-index: 9999;
  }
}

.prop-search {
  //min-height: 181px;

  margin-top: auto;
  background: #283263;
  background: -moz-linear-gradient(-30deg, rgba(#283263, .5) 0%, rgba(#41599b, .5) 100%);
  background: -webkit-linear-gradient(-30deg, rgba(#283263, .5) 0%, rgba(#41599b, .5) 100%);
  background: linear-gradient(-30deg, rgba(#283263, .5) 0%, rgba(#41599b, .5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#283263', endColorstr='#4172bc', GradientType=1);
  position: relative;
  padding: 50px 0 20px;
  border-radius: 10px;
  margin-bottom: 45px;
  @include clearfix();
  height: auto;
  @media screen and (max-width: $screen-sm) {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 0;
  }
  .irs.js-irs-0 {
    z-index: 1;
    transform: translateY(-18px);
  }
  .range-inputs {
    position: relative;
    z-index: 2;
  }

  .sticky-wrapper.is-sticky {
    #sticker {
      display: flex;
      align-items: center;
      width: auto !important;
      z-index: 500 !important;
      i {
        padding-left: 5px;
      }
      height: 35px !important;
      line-height: 35px !important;
      padding: 0 15px;
      text-align: center;
      background-color: #4172bc;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  #filters-row {
    //min-width: 1310px;
    * {
      white-space: nowrap;
    }
  }
  &.in-props {
    width: 100%;
    padding: 22px 0 0;
    background-color: transparent !important;
    background: none !important;
    margin-bottom: 0;

    .simplebar-track.horizontal {
      bottom: 20px;
    }

    .select2-selection {
      border-color: #4172bc !important;
      border-width: 1px !important;
    }
    .select2-selection__rendered {
      padding-left: 8px !important;
    }
    .select2-search.select2-search--inline {
      padding-left: 0 !important;
    }
    .select2-selection__rendered {
      height: 100%;
      line-height: 40px !important;
      .select2-selection__placeholder {
        line-height: 40px;
      }
    }
    .select2-selection__choice {
      line-height: 40px;
      height: 40px;
    }
    .select2-search__field {
      padding-left: 0 !important;
      height: 100% !important;
      font-weight: 500 !important;
      font-size: 16px !important;
    }
    .select2-selection__placeholder {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
    input[type=checkbox], input[type=radio] {
      border-color: #c5c5c5 !important;
    }
    .quantity {
      input {
        border: 1px solid #c5c5c5 !important;
        color: #484848 !important;
      }
      span {
        color: #484848 !important;
      }
      i {
        color: #41599b !important;
      }
    }
    .antig-input-container {
      &.disabled {
        pointer-events: none;
        opacity: .5;
      }
    }
    .quantity.dist {
      border: 1px solid #c5c5c5;
      border-radius: 5px !important;
      padding-left: 10px;
      span {
        color: #484848 !important;
      }
      input {
        background: transparent;
        //border: 1px solid #c5c5c5;
        border-radius: 0 !important;
        border: none !important;
        width: 100%;
        //padding: 0 10px;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        font-weight: 300;
        //letter-spacing: .5px;
        //transition: all .2s ease-in-out;
      }
    }
    .irs-bar {
      background: #4172bc !important;
    }
    .irs-slider {
      border: 1px solid #4172bc !important;
      background-color: #fff !important;
    }
    .irs-line {
      background-color: #c5c5c5 !important;
    }
    .currencySymbol {
      border: 1px solid #c5c5c5;
    }
    .simplebar-content {
      padding-bottom: 0 !important;
    }
    .dropdown.open {
      .dropdown-toggle {
        background-color: #4172bc;
        color: white !important;
        &:after {
          color: white !important;
        }
      }
    }
    button.dropdown-toggle {
      background-color: transparent;
      border: 1px solid #4172bc;
      line-height: 36px;
      padding-right: 50%;
      min-width: 118px;
      position: relative;
      border-radius: $radius-primary;
      font-weight: 500;
      font-size: 16px;
      &:after {
        content: '\f107';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-family: FontAwesome;
        color: #41599b !important;
      }

      &.selected {
        border-color: #4172bc;
        border-width: 2px;
        background-color: #d5e2f6;
      }

    }
    .dropdown-menu {
      right: 0;
      left: auto;
      transform: translateY(20px);
      border-radius: 0 !important;
      .inner {
        padding: 35px 22px 15px;
        width: 555px;
        max-width: 100%;
        .select2-selection__rendered {
          height: 100%;
          *, & {
            line-height: 40px !important;
          }
        }
        .select2-selection {
          border-color: #c5c5c5 !important;
        }
        .select2-selection__placeholder {
          opacity: .7 !important;
          font-weight: 500 !important;
          line-height: 40px !important;
          font-size: 16px !important;
        }
      }
    }

  }

}

.dinamic-result {
  min-height: 60px;
  color: $dark-blue;
  padding: 15px 0;
  display: flex;
  align-content: center;
  p {
    margin: 0
  }
  @media screen and (max-width: $screen-sm) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.no-pad {
  padding: 0 !important;
}

h2.heading {
  margin: 90px 0 70px 0;
  @media screen and (max-width: $screen-sm) {
    margin: 60px 0 50px 0;
  }
}

#highlights {
  margin-bottom: 72px;
  @media (max-width: 992px) {
    margin-bottom: 30px !important;
    margin-top: 40px !important;
  }
  .high-props {
    .slick-next {
      right: 20px;
      width: 50px;
      height: 50px;
      background: white;
      border-radius: 100%;
      box-shadow: -2px 0 3px 1px rgba(0, 0, 0, 0.5);
      top: 27%;
      &:before {
        font-family: $material;
        content: '\f105';
        font-size: 45px;
        color: #7b7b7b;
        opacity: 1;
        @media screen and (max-width: $screen-sm) {
          font-size: 30px;
        }
      }
      @media screen and (max-width: $screen-sm) {
        right: 6px !important;
      }
    }
    .slick-prev {
      left: 20px !important;
      z-index: 9;
      width: 50px;
      height: 50px;
      background: white;
      border-radius: 100%;
      box-shadow: 2px 0 3px 1px rgba(0, 0, 0, 0.5);
      top: 27%;
      &:before {
        font-family: $material;
        content: '\f104';
        font-size: 45px;
        color: #7b7b7b;
        opacity: 1;
        @media screen and (max-width: $screen-sm) {
          font-size: 30px;
        }
      }
      @media screen and (max-width: $screen-sm) {
        left: 6px !important;
      }
    }

  }
  @media screen and (max-width: $screen-sm) {
    .d-flex.align-items-center {
      display: block !important;
      > :first-child {
        margin-bottom: 20px;
      }
    }
    margin: 80px 0;
    h2.heading {
      max-width: 70%;
      margin: 0 auto 35px auto
    }
  }
}

.prop-block.inmobiliaria {
  .prop-image {
    background-size: contain !important;
    &:after {
      background-color: rgba(0, 0, 0, .25);
    }
  }
  @media (max-width: 992px) {
    display: flex;
    > * {
      flex: 1;
      min-width: 50%;
      &a {
        margin-right: 10px;
      }
    }
    .prop-desc {
      padding: 0;
    }
  }
}

.inner-page.inmo {
  @media (max-width: 992px) {
    .filters.props {
      height: 60px !important;
      > * {
        height: 100%;
      }
      #property-search {
        height: 100%;
      }
    }
    #filters-row {
      height: 60px !important;
      > .row {
        align-items: center !important;
        height: 100%;
        .form-group {
          margin-bottom: 0 !important;
        }
      }
    }
    .container-fluid.custom {
      padding: 0 !important;
      margin-top: 30px !important;
    }
    .input-icon input {
      height: 30px !important;
    }
    .select2-container--default {
      max-height: 30px;
      margin-bottom: 0 !important;
      * {
        line-height: 30px !important;
        height: 30px !important;
        max-height: 30px !important;

      }
    }
  }
  .filters.props #property-search {
    max-height: none;
  }
}

.prop-image-container {
  padding-bottom: 78%;
  height: 0;
  width: 100%;
  overflow: hidden;
  .prop-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-bottom: 78%;
    height: 0;
    width: 100%;
    transition: all .5s;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all .3s;
      transform-origin: center;
    }
    &:hover {
      &:after {
        transition: all .3s;
        background-color: rgba(#000, .35);
      }
    }
  }
}

.prop-desc {
  padding: 40px 0;
}

.bell {
  background: $brand-primary;
  text-align: center;
  height: 78px;
  width: 78px;
  border-radius: 100%;
  margin: auto;
  i {
    line-height: 78px;
    text-align: center;
    margin: 0;
  }
}

.jumbotron {
  padding: 70px 0 50px 0;
}

#contacto-home {
  .inner {
    @extend .brand-gradient;
    color: white;
    position: relative;
    padding: 100px 0;
    @media (max-width: 992px) {
      padding: 50px 0;
    }
    .col1 .content {
      max-width: 370px;
      * {
        line-height: 1.2;
      }
    }
    .col2 .content {
      * {
        line-height: 1.2;
      }
    }
  }
  @media (max-width: 768px) {
    .item {
      display: block !important;
      margin-top: 40px;
    }
  }
}

#professional {
  .inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media (max-width: 992px) {
    .btn-blue {
      width: 100%;
      text-align: center;
      max-width: none;
    }
    .inner {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .content.pt-5.mt-5 {
      margin-top: 20px !important;
      padding-top: 20px !important;
    }
  }
}

.white {
  color: #FFFFFF;
}

#map-on {
  display: none;
}

#articles {
  margin-bottom: 72px;
  h3 {
    min-height: 60px;
    color: $dark-blue;
  }
  .date {
    color: $gray-blue
  }
  .text {
    color: $light-blue
  }

  .prop-image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 280px;
    width: 100%;
    background-position: center;
    position: relative;
  }
  .prop-desc {
    padding: 0;
    background: none;
  }
}

.pagination {
  li a {
    border: 2px solid $gray-default;
    color: $gray-default;
    margin-right: 10px;
    border-radius: 0 !important;
    &:focus, &:hover, &.active {
      border: 2px solid $gray-default;
      background: $gray-default;
      color: white;
      outline: none
    }
  }
}

/*.gm-style .gm-style-iw {
  font-size: 15px;
  overflow: visible !important;
  max-width: 280px !important;
  width: auto!important;
  height: auto!important;

}*/

// .gm-style-iw {
//   width: 240px !important;
//   top: 20px !important;
//   left: 0 !important;
//   background-color: #fff;
//   //box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
//   border-radius: 0;
//   #content {
//     padding: 18px;
//   }
//   .close-icon {
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     right: 10px;
//     top: 10px;
//     span {
//       position: absolute;
//       top: 50%;
//       height: 2px;
//       width: 20px;
//       background-color: #5b5e65;
//       &:first-child {
//         transform: rotate(45deg);
//       }
//       &:last-child {
//         transform: rotate(-45deg);
//       }
//     }
//   }
// }

.img-iw {
  max-height: 222px;
  object-fit: cover;
  width: 100%;
}

.affix {
  position: fixed;
  top: 0;
  width: inherit;
  @media (max-width: $screen-md) {
    position: static;
  }
}

.round-icon {
  background: $brand-primary;
  padding: 7px;
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  i {
    margin: 0;
    line-height: 17px;
  }
}

#map-off {
  float: none;
  width: 100% !important;
  height: 100%;
  #map {
    height: 100% !important;
  }
  transition: all .4s;
}

.flex-100 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dinamic-result {
  #intro-result, #prop-type-section, #neigh-section, #bedrooms-section, #city-section, #cochera-section, #range-value, #rent-section {
    display: none;
  }
  #rent-value, #prop-type-value, #neigh-value, #bedrooms-value, #city-value, #cochera-value, #range-value-min, #range-value-max, #currency-value {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.slide-location {
  @media screen and (max-width: $screen-sm) {
    margin-top: 47px;
  }
}

.currency-block {
  @media screen and (max-width: $screen-md) {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 40px;
    min-width: 20%;
    float: right;
    margin-right: 15px;
    flex-wrap: wrap;
    .mb-1 {
      margin-bottom: 0 !important;
      margin-right: 15px;
    }
  }
  @media screen and (max-width: $screen-xs) {
    label {
      margin-right: 5px;
    }
  }
}

.clearfix {
  clear: both;
}

.affix-bottom {
  position: absolute;
}

.form-title {
  text-align: left;
  text-transform: none;
  font-size: 14px;
  color: $brand-primary
}

.home-contact {
  .btn-primary {
    float: right;
    @media screen and (max-width: $screen-sm) {
      float: none;
      padding: 9px 35px;
    }
  }
}

.row.no-mobile {
  margin: 0
}

.toggle-float {
  float: right;
  @media screen and (max-width: $screen-sm) {
    float: none;
    padding: 9px 35px !important;
  }
}

.green-border {
  border-color: $brand-primary !important;
}

.tooltip-prop {

  position: absolute;
  z-index: 999999999999999999;
  padding: 10px;
  background: #4ab084;
  color: #fff;
  max-width: 160px;
  right: -26px;
  top: -278px;
  display: none;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $brand-primary transparent transparent transparent;
  }

  &.tooltip-panel {
    top: -90px;
    right: auto;
    max-width: 300px;
    left: 0;
  }
}

.custom-w {
  @media (max-width: 1400px) {
    &:not('.dist') {
      max-width: 50% !important;
      width: 50% !important;
      float: left;
    }
  }
  &.dist{
    @media (max-width: 992px) {
      max-width: 100% !important;
      width: 100% !important;
      float: none;
    }
  }
  @media (max-width: 650px) {
    max-width: 100% !important;
    width: 100% !important;
    float: none;
  }
}

.row.custom-flex {
  display: flex;
  flex-wrap: wrap;
}

.panel-rotate {
  background: none !important;
  width: 100% !important;
  height: 100vh !important;
  @media (max-width: 992px) {
    transform: translateX(100%) !important;
    position: absolute !important;
    width: 100% !important;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
  }
  &.fixed {
    width: 33% !important;
    padding-right: 15px;
    position: fixed;
    top: 0;
  }
  &.on-bottom {
    height: calc(100vh - 90px) !important;
  }
}

.container-fluid.custom {
  @media (min-width: 650px) {
    padding-right: 0 !important;
  }
}

.gmnoprint img {
  max-width: none;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.select2-container .selection .select2-search, .select2-container .selection .select2-search__field {
  padding-left: 0 !important;
}

.quantity.home {
  border: 1px solid #c5c5c5;
  border-radius: 5px !important;
  padding-left: 10px;
  background-color: #fff;
  input {
    border: none;
    color: #484848;
  }
  //@media (max-width: 992px) {
  padding-left: 15px !important;
  //border: 1px solid #c5c5c5;
  //border-radius: 5px !important;
  background-color: #fff;
  * {
    color: #484848 !important;
    &i {
      color: #41599b !important;
    }
  }
  span {
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  input {
    font-weight: 700 !important;
    background: transparent;
    border-radius: 0 !important;
    border: none !important;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    margin-left: auto;
    max-width: 50px !important;
  }
  //}
}

.quantity input, .quantity input:focus {
  width: 55px !important;
  height: 40px;
  float: left;
  display: block;
  padding: 0 20px 0 10px;
  margin: 0;
  //border: 1px solid #a8c8f9 !important;
  outline: none !important;
  //border-radius: 5px;
  background-color: transparent !important;
  //color: #fff !important;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 40px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  transform: translateX(-100%);
  user-select: none;
  i {
    font-size: 15.6px;
    color: #FFFFFF;
  }
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 4px;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: 1px;
  height: 50%;
}

#property-search {
  @media (max-width: 992px) {
    .select-no-m {
      display: block !important;
    }
    .currency-block {
      width: 100%;
      float: none;
      margin: 15px 0;
    }
    .quantity {
      input {
        width: 100%;
        flex: auto;
        max-width: 150px;
        float: right;
      }
    }
  }
}

.high-props.slick-initialized.slick-slider {
  .prop-block {
    margin-bottom: 0;
  }
}

.arrow_box {
  background: #fffffd;
  border: 1px solid #c5c5c5;
}

.arrow_box:after, .arrow_box:before {
  bottom: 100%;
  right: 15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 253, 0);
  border-bottom-color: #fffffd;
  border-width: 10px;
  margin-left: -10px;
}

.arrow_box:before {
  border-color: rgba(197, 197, 197, 0);
  border-bottom-color: #c5c5c5;
  border-width: 11px;
  right: 14px;
}

@media (max-width: 992px) {
  .select2-container--open {
    z-index: 190;
  }
}

.filters.props {
  @media (max-width: 992px) {
    height: auto !important;
    z-index: 180;
    position: relative;
    max-height: 100vh;
    overflow: auto;
    #no-antig {
      margin-left: 20px;
    }
    .shadow-filters {
      box-shadow: 0 0 10px -2px;
    }
    .col-md-3.d-flex {
      display: block;
    }
    .removeFilters, .closeFilters {
      display: none;
    }
    .dropdown-menu.arrow_box {
      max-width: 100% !important;
      //right: -15px !important;
      position: relative;
      border: none;
      box-shadow: none;
      &:after, &:before {
        content: none;
      }
      .inner {
        padding: 0 !important;
      }
    }
    .quantity {
      input {
        margin-left: auto;
        max-width: 50px !important;
      }
    }
    #others-filters {
      padding-top: 35px;
      position: relative;
    }
    button.dropdown-toggle {
      border: none;
      text-align: left;
      position: absolute;
      left: -15px;
      width: 100vw;
      border-radius: 0;
      top: 0;
      padding-left: 30px;
      &:after {
        right: 30px;
      }
      .badge.dif {
        right: 50px;
      }
      span {
        display: inline-block !important;
      }
    }

    #property-search {
      max-height: 0;
      padding: 0;
      overflow: hidden;
      transition: all .3s;
    }
    &.open {
      .removeFilters, .closeFilters {
        display: block;
      }
      #property-search {
        max-height: 1000px;
        padding: 22px 0;
        overflow: unset;
      }
    }

    .filters-menu-mobile {
      display: block !important;
    }

    #filters-row {
      .row.pb-2 {
        padding-bottom: 25px !important;
      }
    }
  }
}

.prop-search.in-props {
  @media (max-width: 992px) {
    margin: 0;
    padding-bottom: 0 !important;
  }
}

.closeFilters {
  position: relative;
  width: 20px;
  height: 20px;
  &.home span {
    background-color: #dde0eb;
  }
  span {
    position: absolute;
    top: 50%;
    height: 2px;
    width: 20px;
    left: 0;
    background-color: #676767;
    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}

.pm-0 {
  @media (max-width: 992px) {
    padding: 0;
  }
}

.removeFilters.home {
  &, * {
    color: #8baddf !important;
  }
}

body.single-prop {

}

.input-icon {
  position: relative;
  i {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 16px;
  }
  input {
    border-radius: $radius-primary;
    width: 100%;
    border: 1px solid #4172bc;
    height: 42px;
    padding-left: 36px;
  }
}

.corredor-img {
  width: 21px;
  height: 21px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 1px solid #818181;
}

@media (max-width: 992px) {
  .high-props {
    .prop-desc {
      h4 {
        font-size: 18px !important;
      }
      .real-estate {
        font-size: 14px !important;
      }
    }
  }
}

#map > div > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) {
  * {
    box-shadow: none !important;
  }
}

#map > div > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(1) {
  border-top-color: transparent !important;
}

.single-prop {
  .icon-box.pull-left {
    @media (max-width: 992px) {
      > div {
        display: block !important;
      }
    }
  }
}
