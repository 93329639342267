footer {
    min-height: 90px;
    border-top: 2px solid #e6e6e6;
    display: flex;
    align-items: center;
    .icon.fa-stack.fa {
        width: 45px;
        height: 45px;
        line-height: 45px;
        .fa-stack-2x {
            font-size: 45px;
            line-height: 45px;
            color: #d5d5d5;
        }
        .fa-stack-1x {
            color: #767676;
            font-size: 16px;
            transition: all .3s;
            &:hover{
                &.fa-twitter {
                    color: #0084b4;
                }
                &.fa-facebook {
                    color: #3B5998;

                }
            }
        }
    }
    @media (max-width: 992px) {
        .container > .row{
            display: block !important;
            margin-top: 25px;
            div:last-child{
                margin-top: 10px;
                padding-bottom: 15px;
                > div{
                    margin-left: 0 !important;
                    li:first-child{
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }
}
