#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 35px;
  display: none;
  z-index: 99;
  transition: all 0.3s ease;

  span {
    color: $lighter-blue;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    transition: all 0.3s ease;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.9);
    span {
      color: $lighter-blue;
      top: 5px;
    }
  }
}

// buttons

[class*="btn-"] {
  padding: 16px 45px;
  border-radius: $radius-primary;
  transition: all .3s;
  white-space: nowrap;
  line-height: 1;
  display: inline-block;
  font-weight: 700;
}

.btn-red, .btn-blue {
  font-size: 14px;
  background-color: #fa5949;
  color: #fff !important;
  cursor: pointer;
  &.btn-blue {
    background-color: #4172bc;
    border-style: solid;
    border-color: transparent;
    &.border {
      color: #4172bc !important;
      background-color: transparent;
      background-color: transparent;
      box-shadow: 0 0 0 2px #4172bc;
      &:hover {
        background-color: #4172bc;
        color: #fff !important;
      }
    }
    &:hover {
      background-color: #8baddf;
      box-shadow: none;
      color: #fff;
    }
    &:active {
      background-color: #283263 !important;
      box-shadow: none;
      color: #fff;
    }
  }

  &.lg {
    font-size: 16px;
  }

  &.sm {
    padding: 7px 20px;
    font-size: 12px;
  }

  &.border {
    background-color: transparent;
    box-shadow: 0 0 0 2px #fa5949;
    color: #fa5949;
  }

  &:hover {
    background-color: #fa776a;
    box-shadow: none;
    color: #fff;
  }
  &:active {
    background-color: #fa3f2b;
    box-shadow: none;
    color: #fff;
  }
}

.btn-register {
  padding: 16px 45px;
  border-radius: $radius-primary;
  background-color: #fff;
  border: 2px solid #fff;
  color: #415b9e !important;
  transition: all .3s;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1;
  display: inline-block;
  &:hover {
    border-color: #fff !important;
    color: #fff !important;
    background-color: transparent !important;
  }
}

.btn-login {
  color: #41599b !important;
  padding: 10px 20px;
  transition: all .3s;
  background-color: #a8c8f9;
  font-size: 14px;
  border-radius: $radius-primary;
  font-weight: 700;
  line-height: 20px;
  @media (max-width: 992px){
    padding: 10px 15px;
    font-size: 13px;
  }
  &:hover {
    background-color: #d5e2f6;
  }
  &:active {
    background-color: #8baddf;
  }

}

// inputs

.form-group {
  //margin: 10px 0;
  margin-bottom: 30px;
  label {
    color: #484848;
    font-size: 16px;
    font-weight: 700;
    margin-left: 0;
  }
  &.has-error {
    position: relative;
    input, textarea {
      border: 2px solid #fa5949;
      background-color: #fae6cd;
      color: #484848;
      position: relative;
    }
    &:after {
      content: '× Requerido';
      position: absolute;
      bottom: -20px;
      right: 0;
      color: #fa3f2b;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &.is-valid {
    input {
      border: 2px solid #4172bc;
      background-color: #f5f5f3;
      color: #484848;
    }
  }

}

input[type="text"], input[type="number"], input[type="email"], input[type="tel"], textarea {
  background: transparent;
  border: 1px solid #c5c5c5;
  width: 100%;
  color: #484848;
  padding: 0 10px;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  font-weight: 300;
  letter-spacing: .5px;
  transition: all 0.2s ease-in-out;
  &::-webkit-input-placeholder {
    color: #797979;
  }
  &:focus {
    border: 2px solid #4172bc;
    background-color: #f5f5f3;
    color: #484848;
  }

  //&:invalid {
  //    border: 2px solid #fa5949;
  //    background-color: #fae6cd;
  //    color: #484848;
  //    position: relative;
  //    &:after {
  //        content: ' Error Mensaje';
  //        position: absolute;
  //        bottom: -30px;
  //        right: 0;
  //    }
  //}
}

//estilos del select

// estilos fondo azul claro

#contacto-home {

  input[type="text"], input[type="number"], input[type="email"], input[type="tel"], textarea,
  .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple,
  .select2-container--default .select2-results .select2-results__options {
    background: $light-blue;
    border: 2px solid $new-light-blue;
    color: $light-beige;
  }

  input, textarea {
    &:not(.select2-search__field):focus {
      border: 2px solid $brand-primary;
      transition: all 0.2s ease-in-out
    }
  }

}

// resetear checkbox de los select que no lo necesitan

#select2-currency-results, #select2-rent-contact-results {

  .select2-results__option, .select2-results__option[aria-selected=true] {
    &:after {
      content: "";
      width: 0;
      height: 0;
      background: none;
      border: none
    }
  }
}

// borde caja contenedora

#select2-city-results, #select2-neigh-results, #select2-bedrooms-results, #select2-rent-results, #select2--6s-results {
  border: 2px solid #d8d8d8 !important;
}

// select disabled

.select2-results__option[aria-disabled="true"] {
  text-transform: uppercase;
  &:after {
    opacity: 0;
  }
}

// select de ordenar por

#ordenar-resultados {
  + .select2-container--default {
    width: 180px !important;
    background: transparent;
    margin-bottom: 0;
    .select2-selection--single {
      border-color: $gray-default !important;
      background: white;
      .select2-selection__rendered {
        color: $gray-default !important;
      }
    }
    .select2-selection__placeholder {
      color: $gray-default !important;
    }
  }
  + .select2-container--open {
    background: transparent !important;
  }
}

#select2-ordenar-resultados-results {
  background: white !important;
  color: $gray-default !important;
  border: 2px solid $gray-default !important;
  .select2-results__option--highlighted[aria-selected] {
    background: $gray-light !important;
    color: white !important;
  }
  .select2-results__option[aria-selected=true], .select2-results__option:hover {
    background: $gray-light !important;
    color: white !important;
  }
}

// select de quiero alquilar o vender - contacto sobre nosotros

#rent-contact {
  + .select2-container--default {
    width: 130px !important;
    margin-bottom: 0;
    .select2-selection--single {
      border-color: $light-green !important;
      .select2-selection__rendered {
        color: white !important;
      }
    }
    .select2-selection__placeholder {
      color: white !important;
    }
  }
  + .select2-container--open {
    background: transparent !important;
    & > .select2-dropdown {
      border: 2px solid white !important;
    }
  }
}

#select2-rent-contact-results {
  background: $brand-primary !important;
  color: white !important;
  border: 2px solid white !important;
  .select2-results__option--highlighted[aria-selected] {
    background: $brand-primary !important;
    color: white !important;
  }
  .select2-results__option[aria-selected=true], .select2-results__option:hover {
    background: $light-green !important;
    color: white !important;
  }
}

form.green-form {
  margin-top: 50px;
  input[type="text"], textarea,
  .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple,
  .select2-container--default .select2-results .select2-results__options {
    background: $greeny-blue;
    border: 2px solid $light-green;
    color: white;
    &::-webkit-input-placeholder {
      color: white;
    }
  }
}

//checkbox

/* Checkbox */

input[type="radio"], input[type="checkbox"] {
  background: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -webkit-transition: .15s;
  position: relative;
  border: 1px solid #767676;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  &.blue {
    border-color: #c5c5c5;
  }
  &.white {
    border-color: #ffffff;
    ~ label {
      color: #ffffff !important;
    }
  }
}

input[type="radio"].white ~ label, input[type="checkbox"].white ~ label,
input[type="radio"].white:checked ~ label, input[type="checkbox"].white:checked ~ label {
  color: white;
  font-weight: 500;
  cursor: pointer;
}

input[type="radio"].blue:checked ~ label, input[type="checkbox"].blue:checked ~ label {
  color: #767676;
}

input[type="radio"].white:checked, input[type="checkbox"].white:checked {
  border-color: transparent;
  box-shadow: none;
  outline: none;
  &:after {
    font-size: 20px;
  }
  &.white:after {
    color: white;
  }
}

input[type="radio"]:checked, input[type="checkbox"]:checked {
  box-shadow: none;
  outline: none;
  &:after {
    font-size: 20px;
    position: relative;
  }
  &.white:after {
    color: white;
  }
}

input[type="radio"]:checked:after, input[type="checkbox"]:checked:after {
  content: "\f00c";
  position: absolute;
  top: 1px;
  left: 1px;
  color: $brand-primary;
  font-family: FontAwesome;
  font-size: 15px;
}

input[type="radio"].white:checked:after, input[type="checkbox"].white:checked:after {
  content: "\f14a";
  position: absolute;
  top: -2px;
  left: -1px;
  color: $brand-primary;
  font-family: FontAwesome;
  font-size: 20px;

}

/* Label */

// Input range

.irs-line, .irs-bar {
  border-radius: 0 !important;
  height: 3px !important;
  top: 34px;
}

.irs-line {
  background: #8baddf !important;
  border: none !important;
  height: 3px;
}

.irs-bar {
  background: #ffffff !important;
  border-top: none !important;
  border-bottom: none !important;
}

.irs-from, .irs-to, .irs-single {
  background: transparent !important;
  &:after {
    border: none
  }
}

.irs-from, .irs-to {
  visibility: visible !important;
  font-weight: 500;
  font-size: 14px;
}

.irs-single {
  visibility: hidden !important;
}

.irs-from, .irs-single {
  left: 0 !important;
}

.irs-to {
  left: auto !important;
  text-align: right;
  right: 0;
}

.irs-slider {
  top: 26px !important;
  width: 19px !important;
  height: 19px !important;
  background: #41599b !important;
  box-shadow: none !important;
  border: 1px solid #ffffff !important;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &.state_hover, &.active {
    cursor: move;
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.irs-max, .irs-min {
  background-color: transparent !important;
}

.ordenar-por {
  float: right;
  @media screen and (max-width: $screen-sm) {
    float: left;
    margin: 25px 0;
    width: 100%
  }
}

#vermapa, #verlista {
  @media screen and (max-width: $screen-sm) {
    float: left;
    width: 50%;
  }
}

.refresh-prop {
  background-color: transparent;
  border: 1px solid #8baddf;
  border-radius: $radius-primary;
  height: 40px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  i {
    color: #8baddf;
    font-size: 16px;
    line-height: 37px;
    margin-right: 0;
  }
}