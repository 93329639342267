// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Fonts
$font: 'Muli', sans-serif;
$thin: 100;
$light: 300;
$bold: bold;
$medium: 500;
$black: 900;
$semibold: 500;
$extrabold: 800;
$material: "Fontawesome";
$radius-primary: 6px;

// Overlay

@mixin gradient-overlay($start-color: #555, $end-color: #333, $deg: 45deg, $image: '') {
  background: linear-gradient($deg, $start-color, $end-color), url($image);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

//// Colors
//Grays
$dark_gray: #484848;
$dark_gray2: #767676;
$medium_gray: #c5c5c5;
$light_gray: #d8d8d8;
$ultra_light_gray: #f5f5f3;

// Blues
$dark_blue: #283263;
$dark_blue2: #41599b;
$medium_blue2: #4172bc;
$light_blue: #8baddf;
$light_blue2: #a8c8f9;
$light_blue3: #d5e2f6;

//Reds
$dark_red: #fa3f2b;
$medium_red: #fa5949;
$light_red: #fa776a;
$biege: #fae6cd;

$brand-primary: $medium_blue2;
$brand-secondary: $dark_blue2;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
) !default;

/* REMOVE THIS */
// Green
$light-green: #5ee0a8;
$greeny-blue: #51c191;

// Neutral
$cream: #f2f2f0;
$beige: #d2bea5;
$light-beige: #deded6;
$gray-default: #c5c5c5;
// Blues
$dark-blue: #253034;
$semiblue: #303c40;
$med-blue: #3c484c;
$new-light-blue: #62767c;
$light-blue: #516166;
$gray-blue: #889498;
$lighter-blue: #deded6;
// Striped
/* END REMOVE THIS */

@mixin rayas-diagonales($color1: '',  $color2: '', $distance: 20, $angle: 45deg) {
  background: repeating-linear-gradient(
                  $angle,
                  $color1,
                  $color1 $distance+px,
                  $color2 $distance+px,
                  $color2 $distance*2+px)
}
